<template>
  <v-btn 
    depressed
    :block="!noBlock" 
    :class="
    [
      'azul2--text', 
      leftFlat ? 'rounded-l-0' : ''
    ]"
    light
    color="white"
    @click="$emit('click')"
    min-width="150px">
    <v-img 
      class="mr-2 pl-0" 
      max-width="12px" 
      :src="
        arrowRight ? 'assets/ic-arrow-blue-right.svg' : 'assets/ic-arrow-blue-bottom.svg'
      " />
    MÁS INFO
    </v-btn>
</template>

<script>
export default {
  name: 'MasInfoButton',
  props: {
    leftFlat: {
      type: Boolean,
      default: false
    },
    arrowRight: {
      type: Boolean,
      default: false
    },
    noBlock: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>